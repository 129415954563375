'use client'
import { Button } from '@components/ui/button'
import Link from 'next/link'
import React from 'react'

const NotFoundGoBackBtn = () => {
  return (
    <div>
      <Link href={'/'}>
        <Button className="w-full title_medium text-white ">
          <div className="py-4">GO BACK TO HOMEPAGE</div>
        </Button>
      </Link>
    </div>
  )
}

export default NotFoundGoBackBtn
